export const maps = {

    virtualOfficeMaps: [
        {
            url: "https://workadventure.github.io/great-place-to-work/map.json",
            image: "great-place-to-work.png",
            name: "Great Place to Work",
            tags: [
                "workshops",
                "hackathon",
                "job_fairs",
                "trade_fairs",
                "other_event",
                "work",
                "chill",
                "collaborate",
                "event",
                "festive",
                "summer",
            ],
            users: [150, null],
        },
        {
            url: "https://workadventure.github.io/wam-preset-startup/map.wam",
            image: "startup.png",
            name: "Startup",
            tags: [
                "virtual_office",
                "workshops",
                "job_fairs",
                "trade_fairs",
                "employees_onboardings",
                "networking_team_building",
                "other_event",
                "work",
                "chill",
                "collaborate",
                "event",
                "winter",
            ],
            users: [10, 30],
        },
        {
            url: "https://workadventure.github.io/huge-open-space/map.json",
            image: "huge-open-space.png",
            name: "Huge Open Space",
            tags: [
                "virtual_office",
                "employees_onboardings",
                "networking_team_building",
                "other_event",
                "work",
                "collaborate",
                "event",
                "urban",
            ],
            users: [70, 150],
        },
        {
            url: "https://workadventure.github.io/underwater-office/map.json",
            image: "underwater-office.png",
            name: "Underwater Office",
            tags: [
                "virtual_office",
                "webinars",
                "workshops",
                "hackathon",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "work",
                "collaborate",
                "event",
                "urban",
                "medieval",
                "futuristic",
                "winter",
            ],
            users: [50, 100],
        },
        {
            url: "https://workadventure.github.io/nature-office/map.json",
            image: "nature-office.png",
            name: "Nature Office",
            tags: [
                "virtual_office",
                "employees_onboardings",
                "networking_team_building",
                "other_event",
                "work",
                "chill",
                "collaborate",
                "event",
                "urban",
                "festive",
                "summer",
            ],
            users: [10, 30],
        },
        {
            url: "https://workadventure.github.io/space-station-office/map.json",
            image: "space-station-office.png",
            name: "Space Station Office",
            tags: [
                "virtual_office",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "work",
                "chill",
                "collaborate",
                "event",
                "futuristic",
            ],
            users: [20, 100],
        },
        {
            url: "https://workadventure.github.io/open-spaces/map.json",
            image: "open-spaces.png",
            name: "Open Spaces",
            tags: [
                "virtual_office",
                "employees_onboardings",
                "networking_team_building",
                "other_event",
                "work",
                "chill",
                "collaborate",
                "event",
                "urban",
                "festive",
                "summer",
            ],
            users: [50, 100],
        },
        {
            url: "https://workadventure.github.io/agency/map.json",
            image: "agency.png",
            name: "Agency",
            tags: [
                "virtual_office",
                "workshops",
                "hackathon",
                "employees_onboardings",
                "other_event",
                "work",
                "chill",
                "collaborate",
                "event",
                "urban",
                "festive",
                "summer",
            ],
            users: [20, 50],
        },
        {
            url: "https://workadventure.github.io/workspace/map.json",
            image: "workspace.png",
            name: "Workspace",
            tags: [
                "virtual_office",
                "workshops",
                "hackathon",
                "social_meetups",
                "other_event",
                "work",
                "chill",
                "collaborate",
                "event",
                "urban",
                "festive",
                "summer",
            ],
            users: [20, 60],
        },
    ],
    eventMaps: [
        {
            url: "https://workadventure.github.io/wam-preset-workshop/map.wam",
            image: "workshop.png",
            name: "Workshop",
            tags: [
                "workshops",
                "hackathon",
                "job_fairs",
                "trade_fairs",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "collaborate",
                "event",
                "presentation",
                "urban",
            ],
            users: [100, 200],
        },
        {
            url: "https://workadventure.github.io/wam-preset-webinar/map.wam",
            image: "webinar.png",
            name: "Webinar",
            tags: [
                "workshops",
                "hackathon",
                "job_fairs",
                "trade_fairs",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "collaborate",
                "chill",
                "event",
                "presentation",
                "urban",
                "festive",
                "summer",
            ],
            users: [150, 300],
        },
        {
            url: "https://workadventure.github.io/wam-preset-team-building/map.wam",
            image: "team-building.png",
            name: "Team Building",
            tags: [
                "webinars",
                "workshops",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "chill",
                "collaborate",
                "event",
                "presentation",
                "urban",
                "festive",
            ],
            users: [20, 50],
        },
        {
            url: "https://workadventure.github.io/hackathon/map.json",
            image: "hackathon.png",
            name: "Hackathon",
            tags: [
                "workshops",
                "hackathon",
                "job_fairs",
                "trade_fairs",
                "networking_team_building",
                "other_event",
                "chill",
                "collaborate",
                "event",
                "presentation",
                "urban",
                "festive",
                "summer",
            ],
            users: [150, 300],
        },
        {
            url: "https://workadventure.github.io/networking/map.json",
            image: "networking.png",
            name: "Networking",
            tags: [
                "workshops",
                "hackathon",
                "job_fairs",
                "trade_fairs",
                "networking_team_building",
                "other_event",
                "chill",
                "collaborate",
                "event",
                "presentation",
                "medieval",
                "festive",
                "summer",
            ],
            users: [150, 300],
        },
        {
            url: "https://workadventure.github.io/wam-preset-small-workshop/map.wam",
            image: "small-workshop.png",
            name: "Small Workshop",
            tags: [
                "workshops",
                "hackathon",
                "job_fairs",
                "trade_fairs",
                "networking_team_building",
                "other_event",
                "work",
                "collaborate",
                "event",
                "presentation",
                "urban",
            ],
            users: [150, 300],
        },
        {
            url: "https://workadventure.github.io/wam-preset-sponsorship/map.wam",
            image: "sponsorship.png",
            name: "Sponsorship",
            tags: [
                "workshops",
                "hackathon",
                "job_fairs",
                "trade_fairs",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "collaborate",
                "event",
                "presentation",
                "urban",
                "summer",
            ],
            users: [250, null],
        },
        {
            url: "https://workadventure.github.io/speed-networking/map.json",
            image: "speed-networking.png",
            name: "Speed Networking",
            tags: [
                "webinars",
                "workshops",
                "hackathon",
                "job_fairs",
                "trade_fairs",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "work",
                "collaborate",
                "event",
                "presentation",
                "urban",
                "festive",
                "summer",
            ],
            users: [10, 30],
        },
    ],
    chillMaps: [
        {
            url: "https://workadventure.github.io/chillin-at-the-beach/map.json",
            image: "chillin-at-the-beach.png",
            name: "Chillin' at the Beach",
            tags: [
                "workshops",
                "hackathon",
                "job_fairs",
                "trade_fairs",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "chill",
                "collaborate",
                "event",
                "medieval",
                "festive",
                "summer",
            ],
            users: [150, 500],
        },
        {
            url: "https://workadventure.github.io/space-station-among-us/map.json",
            image: "space-station-among-us.png",
            name: "Space Station Among Us",
            tags: [
                "workshops",
                "hackathon",
                "job_fairs",
                "trade_fairs",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "work",
                "chill",
                "collaborate",
                "event",
                "futuristic",
                "festive",
            ],
            users: [100, 300],
        },
        {
            url: "https://workadventure.github.io/island/map.json",
            image: "island.png",
            name: "Island",
            tags: [
                "webinars",
                ,
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "chill",
                "collaborate",
                "event",
                "medieval",
                "summer",
            ],
            users: [50, 200],
        },
        {
            url: "https://workadventure.github.io/speakeasy/map.json",
            image: "speakeasy.png",
            name: "Speakeasy",
            tags: [
                "webinars",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "chill",
                "collaborate",
                "event",
                "urban",
                "festive",
            ],
            users: [10, 30],
        },
        {
            url: "https://workadventure.github.io/game-room/map.json",
            image: "game-room.png",
            name: "Game Room",
            tags: [
                "webinars",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "chill",
                "collaborate",
                "event",
                "urban",
                "festive",
                "winter",
            ],
            users: [10, 50],
        },
        {
            url: "https://workadventure.github.io/merry-christmas/map.json",
            image: "merry-christmas.png",
            name: "Merry Christmas",
            tags: [
                "webinars",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "chill",
                "collaborate",
                "event",
                "urban",
                "festive",
                "winter",
            ],
            users: [10, 50],
        },
        {
            url: "https://workadventure.github.io/we-love-france/map.json",
            image: "we-love-france.png",
            name: "We Love France",
            tags: [
                "webinars",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "chill",
                "collaborate",
                "event",
                "medieval",
                "summer",
            ],
            users: [10, 50],
        },
    ],
    eLearningMaps: [
        {
            url: "https://workadventure.github.io/wam-preset-university/map.wam",
            image: "university.png",
            name: "University",
            tags: [
                "workshops",
                "hackathon",
                "job_fairs",
                "trade_fairs",
                "employees_onboardings",
                "networking_team_building",
                "social_meetups",
                "other_event",
                "work",
                "learn",
                "collaborate",
                "event",
                "presentation",
                "urban",
                "festive",
                "summer",
            ],
            users: [50, 100],
        },
        {
            url: "https://workadventure.github.io/classroom/map.json",
            image: "classroom.png",
            name: "Classroom",
            tags: [
                "webinars",
                "workshops",
                "hackathon",
                "employees_onboardings",
                "networking_team_building",
                "other_event",
                "work",
                "learn",
                "collaborate",
                "event",
                "presentation",
                "urban",
            ],
            users: [20, 40],
        },
        {
            url: "https://workadventure.github.io/school/map.json",
            image: "school.png",
            name: "School",
            tags: [
                "webinars",
                "workshops",
                "hackathon",
                "employees_onboardings",
                "networking_team_building",
                "other_event",
                "work",
                "learn",
                "chill",
                "collaborate",
                "event",
                "presentation",
                "urban",
                "summer",
            ],
            users: [20, 60],
        },
    ],
    eCommerceMaps: [],
};
export const urlListByKey = Object.keys(maps).reduce((object, key) => {
    if (!object[key]) {
        object[key] = [];
    }
    object[key] = object[key].concat(
        maps[key].reduce((urls, object) => {
            urls.push(object.url);
            return urls;
        }, [])
    );
    return object;
}, {});
Vue.component("list-map", {
    props: {
        tabFocus: String,
    },
    mounted() {
        $("#" + this.tabFocus).tab("show");
    },
    computed: {
        mapUrlList() {
            return urlListByKey;
        },
    },
});
